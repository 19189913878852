@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
    --secondary-color: rgb(255, 255, 255);
    --primary-color: rgb(111, 173, 27);    
}

body {
    margin: 0;
    background: rgb(230, 228, 223);
    font-family: 'Poppins';
}

.not-found {
    min-height: 100vh;
    text-align: center;
}

.logo {
    height: 5em;
}

.footer {
    text-align: center;
    color: grey;
    background: var(--secondary-color);
    display: flow-root;
    line-height: .5em;

    .container {
        display: block;
    }
    
}

.navbar {
    padding: .5em;
    background: var(--secondary-color);

    nav {
        display: none;
    }    

    .container {
        display: flex;
        place-content: space-between;
        
        .mobile-menu {
            cursor: pointer;
        }

        .mobile-menu-exit {
            cursor: pointer;
        }
    }
}

nav.menu-btn {
    display: block;
}

ul {
    list-style-type: none;
    margin: 0;
}   

nav {
    position: fixed;
    z-index: 999;
    width: 50%;
    right: 0;
    top: 0;
    background: var(--primary-color);
    height: 100vh;
    padding: 1em;
    text-align: right;

    ul {        
        text-align: right;

        li {
            margin: 1em;

            a {                
                color: white;
                text-decoration: none;
                font-size: 1.2em;

                &:hover {
                    font-weight: bold;
                }
            } 
        }               
    }

    ul.primary-nav {
        margin-top: 2.5em;
    }    
}

section {
    padding: 2em 2em;
    border-bottom: 2px solid;
}

.hero {
    text-align: center;
    background-image: url('./images/Hero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 35%;

    .hero-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .list-items {
            display: flex;
            flex-direction: column;
            color: white;
        }
    }

    img {
        width: 100%;
    }

    .header-sub {
        color: black;      
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .3em;
    }
    
    h1 {
        color: var(--primary-color);         
        font-size: 2.5em;
        margin-top: .2em;
        margin-bottom: 2.5em;
    }

    .left-col {
        align-self: center;
    
        img{
            width: 100%;
            max-height: 326px;
            max-width: 640px;
        };        
    }
    
    .right-col {
        align-self: center;
    
        img {
            border: 10px solid var(--secondary-color);
            width: 80%;
            max-width: 20em;
        }
    }

    p {
        margin-top: 3em;
        border: 5px solid var(--primary-color);
        background: white;
        padding: 1em;
        text-align: left;
        background-color: rgba(0,0,0,0.50);
        color: white;
    }
    
}

.spaceout {
    justify-content: space-around;
}

.services-section {
    background: rgb(200, 224, 167);
    color: black;      

    .container {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        .services-blurb {
            margin-bottom: 1em;
            margin-top: 1em;        
        }
    }

    .services-heading {
        text-align: center;
        margin-bottom: 1.5em;
    }
}

.testimonials-section {
    background: #cccaca;
    color: black;

    .card-container {
        display: flex;
        flex-direction: column;
    }

    .testimonial-card {
        color: var(--primary-color);
        background: var(--secondary-color);
        text-align: center;
        padding-top: 2em;
        padding-bottom: 2em;
        margin: 3em 1em 0em 1em;
        border-radius: 1em;
        list-style-type: none;
        min-width: 10em;
        border: 10px solid;

        .face {
            width: 5em;
            height: 5em;
            border: 5px solid var(--primary-color);
            border-radius: 50%;
            margin-top: -4.5em;
            background-color: white;
        }

        blockquote {
            color: black;
            margin: 1em;
        }

        cite {
            color: black;
            margin: 1em;
            display: block;
        }
    }

    h1 {
        text-align: center;
        margin-bottom: 2.5em;
    }

    .arrow {
        height: 4em;
        border-radius: 40%;
        border: 3px solid var(--primary-color);
        background: var(--secondary-color);
        margin-top: 15em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        user-select: none;

        img {
            height: 2em;
        }
    }

}

.contact {
    color: black;
    padding: 2em 2em;
    background-image: url('./images/pro-contact-v3.jpg'); // Keep?
    background-size: cover; // Keep?
    background-repeat: no-repeat; // Keep?
    background-position: center; // Keep?

    .quote-form-container {
        text-align: left;
    }

    .quote-overview {
        text-align: left;
        margin-bottom: 2em;

        h2 {
            font-size: 2em;
        }  
    }

    .container {
        text-align: -webkit-center;
    }

    svg {
        vertical-align: middle;
    }

      

    label {
        font-size: 1.2em;
        display: block;
        margin-bottom: .5em;
    }

    input, textarea, select {
        border: 3px solid var(--primary-color);
        border-radius: .5em;
        width: 100%;
        margin-bottom: 1em;
        box-sizing: border-box;
        padding: .8em;
    }

    button[type="submit"] {
        color: black;
        border: 3px solid var(--primary-color);
        border-radius: 5em;
        padding: 1em;
        margin-bottom: .4em;
        cursor: pointer;
        width: unset;
        font-weight: bold;
        background: var(--secondary-color);        

        &:hover {
            background: var(--primary-color);
            font-weight: bold;
            color: var(--secondary-color);
        }

        svg {
            vertical-align: sub;
        }
    }

    input:invalid {
        border: 3px solid red;
    }

    strong {
        color: red;
        margin-top: -.5em;
        margin-bottom: .5em;
        display: block;
    }

    .input-error {
        border: 3px solid red;
    }
    
}

.left-col-quote {

    form {
        text-align: left;
    }
}

.right-col-quote {
    margin-top: 2em;

    svg {
        margin-bottom: 0.3em;
    }
}

.submit {
    display: flex;
}

.loader {
    margin-left: 2em;
}

.services-sect {
    background-image: url('./images/Pro-Tree-V3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.main-services {  

    .services-overview {
        color: white;
    }

    .container {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
}

.services-block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
}

.portfolio-block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 2fr));
}

.home-service-card {
    display: block;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
    text-decoration: none;
    color: black;
}

.service-card {
    background: var(--secondary-color);
    text-align: center;
    height: 200px;
    width: 120px;
    padding: 0.5em;
    margin-top: 0.5em;
    cursor: pointer;    

    &:hover {
        background: var(--primary-color);
        color: var(--secondary-color);
    }

    .scroll-box {
        overflow-y: auto;
        padding: .25rem;
        max-height: 180px;
    }

    img {
        height: 80%;
        width: inherit;
    }

    h3 {
        font-size: .8em;
    }

    .font-head {
        font-size: .8em;
    }

    p {
        font-size: 0.7em;
        margin: 0;
    }
}

.portfolio-card {
    border: 3px solid #ffffff94;
    text-align: center;
    width: 90%;
    margin-top: 0.5em;
    cursor: pointer; 
    color: #ffffff94;

    &:hover {
        border-color: var(--secondary-color);
        color: var(--secondary-color);
    }

    img {
        width: 100%;
    }

    h3 {
        margin-bottom: .3em;
        margin-top: -2em;
        font-size: 2em;
    }
}

.emergency-container {
    margin-top: 2em;
    text-align: left;

    svg {
        margin-bottom: 0.3em;
        color: red;
    }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    text-align: center;
  }

  iframe {
    width: 100%;
    height: 400px;    
}

.primary-footer {
    background: #2a2a2a;
    padding: 1em 2em;

    .container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    h2 {
        margin: 0;
        padding-top: 2em;
        font-size: 1.9em;
        margin-bottom: 1.5em;
    }

    .links {
        margin-top: 1em;

        h3 {
            color: var(--primary-color);
            font-size: 1.5em;
            margin-bottom: 1.5em;
            text-align: left;
            margin-left: 20px;
        }

        li.current a {
            font-weight: bold;
        }

        a {
            text-decoration: none;
            color: var(--secondary-color);
        }

        ul {
            list-style-type: disclosure-closed;
            text-align: left;

            li {
                margin: 2em 0;
            }
        }
        
    }

    .accreditations {
        margin-top: 1em;
    }
    
    img {
        width: 100%;
        max-width: 220px;
        max-height: 250px;
    }
    
}

.free-quote {
    width: 100%;
    height: 250px;
    background-image: url('./images/AfterPhoto2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;

    a {
        border: 3px solid var(--primary-color);
        border-radius: 0.5em;
        padding: 0.25em;
        background-color: var(--primary-color);
        color: white;
        text-decoration: none;
        font-size: 1.1em;
    }
}
  

@media only screen and (min-width: 768px) {   
    
    .hero {
        .hero-content {

            .list-items {
                flex-direction: row;
            }
        }
    }    

    .container {
        .larger-font {
            font-size: 1.2em;
        }
    }    

    .services-section {

        .services-heading {
            margin-left: 3em;
            text-align: left;
        }  

        .container {
            .services-blurb {
                margin-left: 3.8em;
                margin-right: 3.8em;  
                font-size: 1.2em;          
            }
        }
    }

    .home-service-card {
        width: 35%;
    }

    .services-block {
        grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
    }

    .service-card {
        height: 260px;
        width: 160px;

        .scroll-box {
            max-height: 240px;
        }

        h3 {
            font-size: 1em;
        }

        .font-head {
            font-size: 1em;
        }
    
        p {
            font-size: 1em;
        }        
    }

    .testimonials-section {
        h1 {
            margin-left: 3em;
            text-align: left;
        }

        .card-container {
            display: flex;
            flex-direction: row;
        }
    }

    .primary-footer {
        .links {
            margin-top: 0;
            width: auto;
        }

        .accreditations {
            width: auto;
            margin-top: 0;
        }
    }

    .free-quote {
        width: 40%;
    }

    .portfolio-block {
        grid-template-columns: repeat(auto-fit, minmax(21.5rem, 2fr));
    }
    
}

@media only screen and (min-width: 800px) {
    .services-block {
        grid-template-columns: repeat(4, minmax(11rem, 1fr));
    }
}

@media only screen and (min-width: 862px) {
    .mobile-menu, .mobile-menu-exit {
        display: none;
    }

    .navbar .container {
        display: grid;
        grid-template-columns: 180px auto;
        justify-content: unset;
    }

    .navbar nav {
        display: flex;
        justify-content: space-between;
        position: unset;
        background: none;
        height: auto;
        width: 100%;
        padding: 0;
        

        ul {
            display: flex;
            margin-top: .6em;
        }

        a {
            color: black;
            padding: .1em 1em;
        }

        li.current a {
            font-weight: bold;
        }

        .contact-cta {
            color: var(--primary-color);
            margin-bottom: .4em;     
            
            .contact-selected {
                background: var(--primary-color);
                color: var(--secondary-color);
                font-weight: bold;
            }

            a{
                border: 3px solid var(--primary-color);
                border-radius: 5em;
                padding: .8em;  
                cursor: pointer; 
                
                &:hover {
                    background: var(--primary-color);
                    color: var(--secondary-color);
                    font-weight: bold;
                }
            }
        }       
        
    }    
}

@media only screen and (min-width: 1080px) {
    .hero {
        
        img {
            margin-top: 0;
        }
    }    

    .services-section {

        ul.services-list {
            grid-template-columns: repeat(3, minmax(15rem, 1fr));
        }

        .services-img {
            display: flex;
            height: 20em;
        }
    }

    section {
        padding: 5em 4em;
    }

    .container {
        display: flex;
        margin: 0 auto;
    }

    .left-col {
        font-size: 2em;
    }

    .right-col {
        position: unset;
    }

    .left-col-quote {
        align-self: center;
        width: 60%;       
    }

    .right-col-quote {
        margin-top: 0;
        margin-left: 5em;
        width: 60%;
        margin-bottom: 3em;

        iframe {
            margin-top: 0.6em;
            height: 92%;
            width: 100%;
        }
        img {
            display: block;
            border: 10px solid var(--secondary-color);
            width: 80%;
        }
    }

    .contact {
        padding: 10em 4em;

        .container {
            display: block;
            text-align: unset;
        }

        .quote-form-container {
            display: flex;
        }
        
        p {
            font-size: 1.2em;
        }
    }

    .service-card {
        height: 320px;
        width: 200px; 
        
        .scroll-box {
            max-height: 300px;
        }
        
        h3 {
            font-size: 1.2em;
        }

        .font-head {
            font-size: 1.2em;
        }
    
        p {
            font-size: 1em;
        }
    }

    .main-services {
        .container {
            display: block;
        }
        
    }

    .primary-footer {
        h2 {
            font-size: 2.5em;
        }

        img {
            margin: 0;
        }
    }

    .portfolio-block {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 2fr));
    }
}

@media only screen and (min-width: 1161px) {
    .container {
        width: 1080px;
    }

    .hero {
        .container {
            justify-content: space-between;
        }

        .about-center {
            text-align: -webkit-center;
        }
        p {
            width: 90%;
        }
    }
}